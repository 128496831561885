<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>

        <template slot="header">
          <span class="mt-2">Panel : {{ details.name }}</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <div id="error" class="card border-danger" v-show="error">
          <div class="card-header bg-danger border-danger">

              <span class="text-white font-weight-bolder">
              <i class="fa fa-exclamation-triangle"></i>
               &nbsp;
              {{ error_heading }}
              </span>

          </div>
          <div class="card-body border-danger text-dark font-weight-bold">

            {{ error_text }}

          </div>
        </div>

        <div>

          <b-form-row class="mb-3">

            <b-col cols="2">
              <label class="">Code</label>
              <b-input v-model="$v.details.code.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateState('code')"></b-input>
              <b-form-invalid-feedback v-if="!$v.details.code.required">This is a required field.</b-form-invalid-feedback>
            </b-col>

            <b-col cols="7">
              <label class="">Name</label>
              <b-input v-model="$v.details.name.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateState('name')"></b-input>
              <b-form-invalid-feedback v-if="!$v.details.name.required">This is a required field.</b-form-invalid-feedback>
            </b-col>

            <b-col cols="3">
              <label class="">Active</label>
              <b-form-select v-model="$v.details.active.$model" :options="options.yes_no" :state="validateState('active')"></b-form-select>
              <b-form-invalid-feedback v-if="!$v.details.active.required">This is a required field.</b-form-invalid-feedback>
            </b-col>

          </b-form-row>

          <b-form-row class="mb-3">

            <b-col>
              <label class="">Description</label>
              <b-input v-model="$v.details.description.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateState('description')"></b-input>
              <b-form-invalid-feedback v-if="!$v.details.description.required">This is a required field.</b-form-invalid-feedback>
            </b-col>

          </b-form-row>

          <b-form-row class="mb-3">

            <b-col>
              <label class="">Insurers</label>
              <b-form-checkbox-group
                v-model="$v.details.insurers.$model"
                :options="dynamic_options.insurer_options"
                :state="validateState('insurers')"
              ></b-form-checkbox-group>
              <b-form-invalid-feedback v-if="!$v.details.insurers.required">This is a required field.</b-form-invalid-feedback>
            </b-col>

          </b-form-row>

          <b-form-row class="mb-3">

            <b-col cols="12">
              <h5 class="mt-3">Commission</h5>
            </b-col>

          </b-form-row>

          <b-form-row class="border-top pb-3 pt-3" v-if="details.commission.length > 0" v-for="(item, index) in details.commission" :key="index">

            <b-col cols="2">
              <label class="">Commission (%)</label>
              <b-form-select v-model="item.commission" :options="dynamic_options.commission_options" :state="validateItemState('commission', index, 'commission')"></b-form-select>
              <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
            </b-col>

            <b-col cols="2">
              <label class="">CSID</label>
              <b-input v-model="item.csid" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateItemState('commission', index, 'csid')"></b-input>
              <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
            </b-col>

            <b-col cols="7">
              <label class="">Insurers</label>
              <b-form-checkbox-group
                v-model="item.insurers"
                :options="dynamic_options.insurer_options"
                :state="validateItemState('commission', index, 'insurers')"
              ></b-form-checkbox-group>
              <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
            </b-col>

            <b-col cols="1" class="text-center">

              <label class="">&nbsp;</label> <br>

              <b-button variant="outline-danger" class="" @click="removeCommission(index)">
                <i class="fa fa-times"></i>
              </b-button>

            </b-col>

          </b-form-row>

          <b-form-row class="mb-3">

            <b-col>
              <b-button variant="outline-success" class="" @click="addCommission">
                <i class="fa fa-plus"></i> Add Commission
              </b-button>
            </b-col>

          </b-form-row>

        </div>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
          <b-button variant="success" @click="editAddon" class="float-right">Edit Panel</b-button>
        </template>

      </b-card>
    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";
  import { validationMixin } from 'vuelidate'
  import {required, requiredIf, decimal, numeric, email} from "vuelidate/lib/validators";

  export default {
    name: 'PanelEdit',
    components: {
      Id,
    },
    mixin: [validationMixin],
    props: {
      caption: {
        type: String,
        default: 'Edit'
      },
    },
    data: () => {
      return {
        details: {
          code: "",
          name: "",
          description: "",
          active: "",
          insurers: [],
          commission: []
        },
        error: false,
        error_heading: '',
        error_text: '',
        server_errors: {
          code: null,
          name: null,
          description: null,
          active: null,
          insurers: null,
        },
        options: {
          yes_no: [
            {
              value: "",
              text: 'Please select'
            },
            {
              value: true,
              text: 'Yes'
            },
            {
              value: false,
              text: 'No'
            }
          ]
        },
        dynamic_options: []
      }
    },
    validations() {

      return {
        details: {
          code: {
            numeric,
            required
          },
          name: {
            required
          },
          description: {
          },
          active: {
            required
          },
          insurers: {
            required
          },
          commission: {
            $each: {
              id: {},
              commission: {
                required
              },
              csid: {
                numeric,
                required
              },
              insurers: {
                required
              },
            }
          },
        }
      }

    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      validateState(name) {

        if (this.$v.details[name]) {
          const {$dirty, $error} = this.$v.details[name];
          return $dirty ? !$error : null;
        }
      },
      validateItemState(type, index, name) {
        if (this.$v.details[type]) {
          if (this.$v.details[type].$each[index]) {
            if (this.$v.details[type].$each[index][name]) {
              const {$dirty, $error} = this.$v.details[type].$each[index][name];
              return $dirty ? !$error : null;
            }
          }
        }
      },
      addCommission() {

        let commission_item = {
          id: null,
          commission: null,
          insurers: [],
          csid: null
        }

        this.details.commission.push(commission_item)
        this.$options.validations()
      },
      removeCommission(id) {
        this.$delete(this.details.commission, id)
        this.$options.validations()
      },
      resetServerError(name) {

        if (this.server_errors[name]) {
          this.server_errors[name] = null;
        }

      },
      getAddon() {
        let url = '';

        url = '/commission/panels/' + this.$route.params.id + '/?product=' + this.$route.params.product + '&mode=edit'

        axios.get(url).then(
          response => {
            this.details = response.data
            console.log(response.data.insurer_options)
            this.dynamic_options['insurer_options'] = response.data.insurer_options
            this.dynamic_options['commission_options'] = response.data.commission_options
          }
        ).catch(error => {

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log('premium error');
            console.log(error.response)
          })
      },
      editAddon() {

        this.error = false;

        this.$v.$touch();

        if(this.$v.$anyError) {

          window.scrollTo(document.querySelector('#error').offsetTop, 100);
          this.error = true;
          this.error_heading = 'There are errors on the form';
          this.error_text = 'Please fix the errors and re-submit the form';
          return

        } else {

          const post_data = JSON.parse(JSON.stringify(this.details));

          let url = '';

          url = '/commission/panels/' + this.$route.params.id + '/?product=' + this.$route.params.product;

          axios.put(url, post_data).then(
            response => (
              this.data = response.data
            )
          ).then(response => {
            response

            let alert = {
              type: 'success',
              text: 'Commission Panel : ' + this.details.name + ' was updated successfully!'
            };

            this.$store.commit(
              'setGlobalAlert',
              alert
            )
            this.$router.replace('/system-config/products/' + this.$route.params.product + '/commissionpanels/')

        }).catch(error => {

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            if (error.response.status === 400) {

              window.scrollTo(document.querySelector('#error').offsetTop, 100);
              this.error = true;
              this.error_heading = 'There are errors on the form';
              this.error_text = 'Please fix the errors and re-submit the form';

              if (error.response.data) {

                //this.server_errors = error.response.data

                for (var key in error.response.data){

                  this.server_errors[key] = error.response.data[key][0]
                }
                this.$v.$reset();
                this.$v.$touch();

              }

            }

            console.log('premium error');
            console.log(error.response)
          });

        }
      }
    },
    mounted() {
      this.getAddon()
      this.$v.details.$reset();
      this.$v.$reset()
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
